import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import '@splidejs/splide/dist/css/splide-core.min.css';
import Splide, { FADE } from '@splidejs/splide'


document.addEventListener( 'DOMContentLoaded', function() {

  var galerie = document.getElementById('primary-slider')

  if ( galerie!=  null) {

    new Splide( '#primary-slider', {
      //heightRatio: 1,
      rewind   : false,
      type: 'fade',
      width : '100vw',
      //height: '560px',
      autoHeight: true,
      autoplay: 'play',
    } ).mount();

  }


  } );