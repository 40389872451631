import $ from 'jquery'

import 'jquery-modal'
import '../../node_modules/jquery-modal/jquery.modal.css';



$(function() {

    /*$('a[data-modal]').on('click', function() {
      $($(this).data('modal')).modal({
        fadeDuration: 100
      });
      return false;
    });*/

    $('.pop-newsletter').on('click', function() {
        $($('#modal-newsletter')).modal({
          fadeDuration: 100
        });
        return false;
      });

  });

const ScrollReveal = require('scrollreveal')

ScrollReveal().reveal('.apparition', {
    delay: 450,
    duration: 1000,
    reset: false,
    opacity: 0,
    scale: 0.5
});

ScrollReveal().reveal('.apparition_bas', {
    delay: 450,
    duration: 1500,
    reset: false,
    opacity: 0,
    scale: 1,
    distance: '50px'
});

ScrollReveal().reveal('.apparition_bas_bis', {
  delay: 650,
  duration: 1500,
  reset: false,
  opacity: 0,
  scale: 1,
  distance: '50px'
});

 ScrollReveal().reveal('.apparition_gauche', {
    origin :'left',
    delay: 450,
    duration: 1500,
    reset: false,
    opacity: 0,
    scale: 1,
    distance: '50px'
});
 
 ScrollReveal().reveal('.apparition_droite', {
    origin :'right',
    delay: 450,
    duration: 1500,
    reset: false,
    opacity: 0,
    scale: 1,
    distance: '50px'
});
 
ScrollReveal().reveal('.fading_fx', {
    delay: 375,
    duration: 500,
    reset: false,
    opacity: 0,
});