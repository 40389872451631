import $ from 'jquery'

class GridProjets extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  resolveElements () {
    this.$posts = $('.posts', this)
    this.$pagination = $('.pagination', this)
  }

  bindFunctions () {
    this.onLoadMore = this.onLoadMore.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-action="loadMore"]', this.onLoadMore)
  }

  onLoadMore (e) {
    e.preventDefault()

    const $target = $(e.currentTarget).addClass('button--disabled')

    const url = new URL(e.currentTarget.href)
    url.searchParams.append('contentOnly', 1)

    $.ajax({
      url: url
    }).then(
      response => {
        const $html = $(response)
        const $posts = $('.posts', $html)
        const $pagination = $('.pagination', $html)

        this.$posts.append($posts.html())
        this.$pagination.html($pagination.html() || '')
      },
      response => {
        console.error(response)
        $target.removeClass('button--disabled')
      }
    )
  }
}

window.customElements.define('flynt-grid-projets-archive', GridProjets, { extends: 'div' })


$(".en_cours").click(function(){
  //do something fired 5 times
  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');

    $('.posts').removeClass('totoro');
    $('.post').each(function(i, obj) {
        $(this).css("display","block");
    });
  } else {
    $(this).addClass('actif');
    $('.posts').addClass('totoro');
    $('.post').each(function(i, obj) {
      if ($(this).hasClass('accompagnement_en_cours')) {
        $(this).css("display","block");
      } else {
        $(this).css("display","none");
      }
    });
  }

});


$(".article").click(function(){
  //do something fired 5 times
  $('#type li').each(function(i, obj) {
    $(this).removeClass('actif');
  });

  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');
    $('.grid-item').each(function(i, obj) {
        $(this).css("display","block");
    });

    
  } else {
    $('#type li').each(function(i, obj) {
      $(this).removeClass('actif');
    });
    $(this).addClass('actif');
    $('.grid-item').each(function(i, obj) {
      if ($(this).hasClass('article')) {
        $(this).css("display","block");
      } else {
        $(this).css("display","none");
      }
    });
  }

});

$(".publication").click(function(){
  //do something fired 5 times
  $('#type li').each(function(i, obj) {
    $(this).removeClass('actif');
  });

  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');
    $('.grid-item').each(function(i, obj) {
        $(this).css("display","block");
    });

    
  } else {
    $('#type li').each(function(i, obj) {
      $(this).removeClass('actif');
    });
    $(this).addClass('actif');
    $('.grid-item').each(function(i, obj) {
      if ($(this).hasClass('publication')) {
        $(this).css("display","block");
      } else {
        $(this).css("display","none");
      }
    });
  }

});

$(".tous").click(function(){
  //do something fired 5 times
  $('#type li').each(function(i, obj) {
    $(this).removeClass('actif');
  });
  $('.grid-item').each(function(i, obj) {
    $(this).css("display","block");
});

  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');
    $('.grid-item').each(function(i, obj) {
        $(this).css("display","block");
    });

    
  } else {
    $('#type li').each(function(i, obj) {
      $(this).removeClass('actif');
    });
    $(this).addClass('actif');
  }

});

$(".touttype").click(function(){
  //do something fired 5 times
  $('#ressources li').each(function(i, obj) {
    $(this).removeClass('actif');
  });
  $('.grid-item').each(function(i, obj) {
    $(this).css("display","block");
});

  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');
    $('.grid-item').each(function(i, obj) {
        $(this).css("display","block");
    });

    
  } else {
    $('#ressources li').each(function(i, obj) {
      $(this).removeClass('actif');
    });
    $(this).addClass('actif');
  }

});

$(".video").click(function(){
  //do something fired 5 times
  $('#ressources li').each(function(i, obj) {
    $(this).removeClass('actif');
  });

  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');
    $('.grid-item').each(function(i, obj) {
        $(this).css("display","block");
    });

    
  } else {
    $('#ressources li').each(function(i, obj) {
      $(this).removeClass('actif');
    });
    $(this).addClass('actif');
    $('.grid-item').each(function(i, obj) {
      if ($(this).hasClass('video')) {
        $(this).css("display","block");
      } else {
        $(this).css("display","none");
      }
    });
  }

});

$(".fichier").click(function(){
  //do something fired 5 times
  $('#ressources li').each(function(i, obj) {
    $(this).removeClass('actif');
  });

  if ($(this).hasClass('actif')) {
    $(this).removeClass('actif');
    $('.grid-item').each(function(i, obj) {
        $(this).css("display","block");
    });

    
  } else {
    $('#ressources li').each(function(i, obj) {
      $(this).removeClass('actif');
    });
    $(this).addClass('actif');
    $('.grid-item').each(function(i, obj) {
      if ($(this).hasClass('fichier')) {
        $(this).css("display","block");
      } else {
        $(this).css("display","none");
      }
    });
  }

});

/**
 * Calcul la position de l'élément par rapport au haut de la page
 * @param {HTMLElement} element
 * @return {number}
 */
function offsetTop(element, acc = 0) {
	if (element.offsetParent) {
	  return offsetTop(element.offsetParent, acc + element.offsetTop);
	}
	return acc + element.offsetTop;
  }
  
  /**
   * @property {HTMLElement} element
   * @property {{y: number, r: number, variable: boolean}} options
   */
  class Parallax {
	/**
	 * @param {HTMLElement} element
	 */
	constructor(element) {
	  this.element = element;
	  this.options = this.parseAttribute();
  
	  this.onScroll = this.onScroll.bind(this);
	  this.onIntersection = this.onIntersection.bind(this);
	  this.onResize = this.onResize.bind(this);
  
	  this.elementY = offsetTop(this.element) + this.element.offsetHeight / 2;
	  const observer = new IntersectionObserver(this.onIntersection);
	  observer.observe(element);
	  this.onScroll();
	}
  
	parseAttribute() {
	  const defaultOptions = {
		y: 0.2,
		r: 0,
		variable: false,
	  };
	  if (this.element.dataset.parallax.startsWith("{")) {
		return {
		  ...defaultOptions,
		  ...JSON.parse(this.element.dataset.parallax),
		};
	  }
	  return { ...defaultOptions, y: parseFloat(this.element.dataset.parallax) };
	}
  
	/**
	 * @param {IntersectionObserverEntry[]} entries
	 */
	onIntersection(entries) {
	  for (const entry of entries) {
		if (entry.isIntersecting) {
		  document.addEventListener("scroll", this.onScroll);
		  window.addEventListener("resize", this.onResize);
		  this.elementY = offsetTop(this.element) + this.element.offsetHeight / 2;
		} else {
		  document.removeEventListener("scroll", this.onScroll);
		  window.removeEventListener("resize", this.onResize);
		}
	  }
	}
  
	onResize() {
	  this.elementY = offsetTop(this.element) + this.element.offsetHeight / 2;
	  this.onScroll();
	}
  
	onScroll() {
	  window.requestAnimationFrame(() => {
		const screenY = window.scrollY + window.innerHeight / 2;
		const diffY = this.elementY - screenY;
		const translateY = diffY * -1 * this.options.y;
		if (this.options.variable) {
		  this.element.style.setProperty("--parallaxY", `${translateY}px`);
		} else {
		  let transform = `translateY(${translateY}px)`;
		  if (this.options.r) {
			transform += ` rotate(${diffY * this.options.r}deg)`;
		  }
		  this.element.style.setProperty("transform", transform);
		}
	  });
	}
  
	/**
	 * @returns {Parallax[]}
	 */
	static bind() {
	  return Array.from(document.querySelectorAll("[data-parallax]")).map(
		(element) => {
		  return new Parallax(element);
		}
	  );
	}
  }
  
  Parallax.bind();