import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import '@splidejs/splide/dist/css/splide-core.min.css';
import Splide, { FADE } from '@splidejs/splide'


document.addEventListener( 'DOMContentLoaded', function() {

  var heroslider = document.getElementById('heroslider')

  if ( heroslider!=  null) {

    new Splide( '#heroslider', {
      //heightRatio: 1,
      rewind   : true,
      type: 'fade',
      width : '100vw',
      height: '640px',
      autoHeight: true,
      autoplay: 'play',
      breakpoints: {
        1024: {
          height: '400px',
        },
      }
    } ).mount();

  }


  var dernierePub = document.getElementById('post-related')

  if ( dernierePub!=  null) {

    new Splide( '#post-related', {
      //heightRatio: 1,
      rewind   : true,
      //type   : 'loop',
      type: 'fade',
      arrows: false,
      width : '100vw',
      height: '580px',
      autoHeight: false,
      autoplay: 'play',
      perPage: 1,
      mediaQuery: 'min',
      breakpoints: {
        1024: {
          destroy: true,
        },
        767: {
          perPage: 1,
        }
      }
    } ).mount();

  }


  } );