import $ from "jquery";
import Tablist from '@accede-web/tablist';

var list = document.querySelector( '#soutien_menu[role="tablist"]' );
if (list) {
	var tablist = new Tablist( list );
	tablist.mount();
}

var list_equipe = document.querySelector( '#equipe_menu[role="tablist"]' );
if (list_equipe) {
	var tablist_equipe = new Tablist( list_equipe );
	tablist_equipe.mount();
}




(function(w,d,undefined){
 
	var el_html = d.documentElement,
		el_body = d.getElementsByTagName('body')[0],
		header = d.getElementsByClassName('ancres')[0],
		menuIsStuck = function() {           


			var wScrollTop	= w.pageYOffset || el_body.scrollTop,
				regexp		= /(nav\-is\-stuck)/i,
				classFound	= el_html.className.match( regexp ),
				navHeight	= header.offsetHeight,
				bodyRect	= el_body.getBoundingClientRect(),
				scrollValue	= 700;
 
			// si le scroll est d'au moins 600 et
			// la class nav-is-stuck n'existe pas sur HTML
			if ( wScrollTop > scrollValue && !classFound ) {
				el_html.className = el_html.className + ' nav-is-stuck';
				el_body.style.paddingTop = navHeight + 'px';
			}
 
			// si le scroll est inférieur à 2 et
			// la class nav-is-stuck existe
			if ( wScrollTop < 700 && classFound ) {
				el_html.className = el_html.className.replace( regexp, '' );
				el_body.style.paddingTop = '0';
			}

		},
		onScrolling = function() {
			// on exécute notre fonction menuIsStuck()
			// dans la fonction onScrolling()
			if (header) {
				menuIsStuck();
			}			
			// on pourrait faire plein d'autres choses ici 
		};
 
	// quand on scroll
	w.addEventListener('scroll', function(){
		// on exécute la fonction onScrolling()
		w.requestAnimationFrame( onScrolling );
	});
 
}(window, document));


/* on fait apparaitre / disparaître le menu au scroll */

var header = document.querySelector("#masthead");
var lastScrollValue = 0;

document.addEventListener('scroll',function() {
    //var top  = document.documentElement.scrollTop;
	var top  = document.documentElement.scrollTop;

	console.log("top : "+top)
	console.log("lastScrollValue : "+lastScrollValue)

    if(lastScrollValue < top) {
        header.classList.add("hidden");
        if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/iPhone XR/i)) || (navigator.userAgent.match(/iPhone XS/i)) || (navigator.userAgent.match(/iPhone XS Max/i))) {
            console.log("c'est un iphone");
			header.classList.remove("hidden");
            //jQuery(".admin-bar header#masthead").css("top","inherit");
            //jQuery(".admin-bar header#masthead").css("opacity","0");
        }
        
    } else {
        header.classList.remove("hidden");
        
        if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/iPhone XR/i)) || (navigator.userAgent.match(/iPhone XS/i)) || (navigator.userAgent.match(/iPhone XS Max/i))) {
            console.log("c'est un iphone");
            //jQuery(".admin-bar header#masthead").css("top","32px");
            //jQuery(".admin-bar header#masthead").css("opacity","1");
        }
        
    }
    lastScrollValue = top;
});
